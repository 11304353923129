<!-- eslint-disable vue/v-on-event-hyphenation -->
<template>
  <component :is="layout" />
</template>

<script setup>
import { ref, onMounted, provide } from 'vue'
import { useRouter } from 'vue-router'
const DateTime = window.luxon.DateTime

function getFormattedNumber(value, showZero = false) {
  if (!value) {
    return showZero ? '0' : ''
  }

  let valueString = value.toString().replace(/\s+/g, '')

  var parts = valueString.split(',')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

  if (parts[1]) {
    let decPart = parseInt(parts[1])

    if (decPart) {
      parts[1] = decPart.toFixed(2)
    }
  }

  return parts.join(',')
}

function getDateFormater(dateValue) {
  let date = DateTime.fromFormat(dateValue, 'yyyy/MM/dd').toFormat('yyyy.MM.dd')
  if (date.isValid) {
    return date
  } else {
    return dateValue
  }
}

provide('util', {
  getFormattedNumber,
  getDateFormater,
})
const router = useRouter()
const intervalScanner = ref(null)
const hasClickEvent = ref(false)

onMounted(() => {
  elementScanner()
})

function elementScanner() {
  intervalScanner.value = setInterval(function () {
    const element = document.getElementById(
      'CybotCookiebotDialogBodyEdgeMoreDetailsLink'
    )
    if (element && !hasClickEvent.value) {
      var new_element = element.cloneNode(true)
      element.parentNode.replaceChild(new_element, element)
      new_element.addEventListener('click', function () {
        hasClickEvent.value = false
        router.push('/cookie-kezeles')
      })
      hasClickEvent.value = true
    }
  }, 1000)
}
</script>

<script>
import Accessible from './app-layouts/AccessibleLayout.vue'
import Default from './app-layouts/DefaultLayout.vue'

export default {
  components: {
    Accessible,
    Default,
  },

  data() {
    return {
      layout: null,
    }
  },

  watch: {
    $route(to) {
      if (to.meta.layout !== undefined) {
        this.layout = to.meta.layout
      } else {
        this.layout = 'Default'
      }
    },
  },
}
</script>

<style lang="scss">
#CookiebotWidget {
  display: none;
}

#CookiebotWidget.CookiebotWidget-open {
  display: block;
}

.home-hero {
  flex-direction: column;
  align-items: center !important;

  .scroll-icon-holder {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
}
.news-section {
  background-color: $N-200;
}
.swiper-section {
  overflow: hidden;
  .h2.medium {
    font-weight: 700;
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 4rem;
  }
}
.benefits-section {
  .row {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .col {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .card-neutral {
    background-color: $N-100;
    border-width: 0;
    border-radius: 0.75rem;
    padding: 2.5rem;
    height: 100%;
    .icon-holder {
      margin-bottom: 2.5rem;
      color: $primary;
      img {
        aspect-ratio: 1;
        height: 5rem;
      }
    }
    .card-title {
      margin-bottom: 1rem;
    }
  }
  .h2.medium {
    font-weight: 700;
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 4rem;
  }
  .h3.small {
    font-weight: 700;
    font-size: 1.75rem;
    line-height: 2.25rem;
    margin-bottom: 1rem;
  }
  p {
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
  .card-body {
    padding: 0;
  }
}
@media screen and (max-width: $breakpoint-lg-max) {
  .benefits-section {
    .row {
      margin-left: -0.25rem;
      margin-right: -0.25rem;
    }
    .col {
      padding-left: 0.25rem;
      padding-right: 0.25rem;
    }
    .card-neutral {
      padding: 1rem;
      .icon-holder {
        margin-bottom: 1.5rem;
      }
    }
    svg {
      width: 2.5rem;
      height: 2.5rem;
    }
    .small {
      font-size: 1.125rem;
      line-height: 1.75rem;
    }
    .h2.medium {
      font-size: 1.75rem;
      margin-bottom: $double;
    }
    .h3.small {
      font-weight: 600;
      font-size: 1.125rem;
      line-height: 1.75rem;
      margin-bottom: $half;
    }
    p {
      font-size: 0.75rem;
      line-height: 1.25rem;
    }
  }
  .swiper-section {
    .h2.medium {
      font-weight: 700;
      font-size: 1.75rem;
      line-height: 2.25rem;
      margin-bottom: 2rem;
    }
    .card-content {
      .xsmall {
        line-height: 1.75rem !important;
        font-weight: 600 !important;
      }
    }
  }
}
@media screen and (max-width: $breakpoint-lg-max) and (min-width: $breakpoint-md) {
  .news-section {
    .card-content {
      .xsmall {
        font-weight: 700 !important;
      }
    }
  }
}
@media screen and (max-width: $breakpoint-sm-max) {
  .benefits-section {
    .h2.medium {
      font-size: 1.5rem;
      line-height: 2rem;
      margin-bottom: 1.5rem;
    }
    .card-neutral {
      .icon-holder {
        margin-bottom: 1rem;
      }
    }
    .h3.small {
      font-weight: 600;
      font-size: 1.125rem;
      line-height: 1.75rem;
      margin-bottom: $half;
    }
  }
  .swiper-section {
    .h2.medium {
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 2rem;
      margin-bottom: 1.5rem;
    }
    h2.xsmall {
      font-weight: 600;
      line-height: 1.75rem;
    }
  }
}

@media screen and (max-width: $breakpoint-xs-max) {
  .benefits-section {
    .h2.medium {
      margin-bottom: 2rem;
    }
    .col {
      margin-bottom: $small/2;
    }
    .card-neutral {
      padding: 1rem;
      .icon-holder {
        height: 1.5rem;
        width: 1.5rem;
        font-size: 1.5rem;
        color: $primary;
        margin: 0 1rem 0 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        svg {
          font-size: 1.5rem;
          height: 1.5rem;
          width: 1.5rem;
        }
        img {
          height: 1.5rem;
        }
      }
      .card-body {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding: 0;
      }
      .card-content {
        text-align: left;
        .h3.small {
          margin-bottom: 0;
        }
        p {
          font-size: 0.75rem;
          line-height: 1.25rem;
        }
      }
    }
  }
}

@media (min-width: $breakpoint-xs) {
  .custom-home-col-sm-4 {
    width: calc(100% / 3) !important;
  }
}
@media (min-width: $breakpoint-sm) {
  .col-md-6 {
    width: 50% !important;
  }
}
.newsletter-section {
  padding: 5rem 0 9.5rem;
  .container {
    width: 56.9375rem;
    max-width: calc(100% - 2rem);
    padding: 2.5rem;
    background: linear-gradient(121.27deg, #fafbfc 31.25%, #faf7f4 100%);
    border: 0.1875rem solid $N-200;
    border-radius: 0.75rem;
    & > .row {
      margin-right: -$normal;
      margin-left: -$normal;
      & > .col {
        padding-right: $normal;
        padding-left: $normal;
      }
    }
  }
  .readmore-link svg {
    margin-left: $half;
  }
  .h2.normal {
    font-weight: 700;
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 1rem;
  }
  p {
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}
@media screen and (max-width: $breakpoint-lg-max) {
  .newsletter-section {
    padding: 2.5rem 2rem;
    .container {
      width: 100%;
      max-width: 100%;
      padding: 2.5rem;
    }
  }
}

@media screen and (max-width: $breakpoint-md-max) {
  .newsletter-section {
    padding: 2rem !important;
    .container {
      width: 100%;
      max-width: 100%;
      padding: 2.5rem;
    }
  }
}

@media screen and (max-width: $breakpoint-sm-max) {
  .newsletter-section {
    padding: 0 !important;
    .container {
      width: 100%;
      max-width: 100%;
      padding: 1.5rem 1rem 2rem;
      background: linear-gradient(121.27deg, #fafbfc 31.25%, #faf7f4 100%);
      border-width: 0;
      border-radius: 0;
    }
    .normal {
      text-align: center;
      margin-bottom: $small;
    }
    .excerpt {
      text-align: center;
      margin-bottom: $large;
    }
    .icon-holder {
      display: none;
    }
    .h2.normal {
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 2rem;
      margin-bottom: 0.25rem;
    }
    p {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
    .row {
      margin-left: 0;
      margin-right: 0;
      .col {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
@media screen and (max-height: 700px) and (min-width: $breakpoint-md) {
  .administration-section {
    padding-top: 2.5rem !important;
  }
}

.hero-skeleton {
  height: 45.25rem;
}

.administration-skeleton {
  height: 43.75rem;
  background-color: $N-100;
}

@media screen and (max-width: $breakpoint-md-max) {
  .hero-skeleton {
    height: 44.75rem;
  }
  .administration-skeleton {
    height: 66.25rem;
  }
}

@media screen and (max-width: $breakpoint-xs-max) {
  .hero-skeleton {
    height: 26.875rem;
  }
  .administration-skeleton {
    height: 64.375rem;
  }
}

.label-responsive {
  .q-item {
    .q-item__section--main {
      height: auto !important;
    }
  }
}

#utasbiztositas-page {
  .hero {
    height: 100% !important;
    padding-bottom: 8.75rem !important;
  }
  .description-section .description-holder span p h3 br {
    display: none;
  }
}
.d-none-important {
  display: none !important;
}

.back-to-top {
  display: none;
}

h2.xsmall {
  font-weight: 700 !important;
}

.wrapperzg .cursor-pointer.all-pointer-events a {
  color: #2f5997;
}
#utasbiztositas-page {
  section.package-compare-section {
    .container-xxl {
      .row {
        margin-left: unset !important;
        margin-right: unset !important;
      }
    }
  }
  @media screen and (max-width: $breakpoint-xl-max) and (min-width: $breakpoint-md) {
    section.package-compare-section {
      .container-xxl {
        padding-left: 2rem !important;
        padding-right: 2rem !important;
      }
    }
  }
}
.product-page {
  section.hero {
    height: 55rem !important;
    flex-direction: column;
  }
  .margintop-correction-12px {
    margin-top: -0.75rem;
  }

  a.d-block.d-xs-none {
    display: none !important;
  }

  @media screen and (max-width: $breakpoint-lg-max) {
    :deep(.prices-section) {
      .swiper-slide.card {
        margin-right: 1rem !important;
      }
    }

    a.d-block.d-xs-none {
      display: none !important;
    }

    @media screen and (max-width: $breakpoint-lg-max) {
      :deep(.prices-section) {
        .swiper-slide.card {
          margin-right: 1rem !important;
        }
      }
    }

    &.product-page-calculator {
      @media screen and (min-width: $breakpoint-lg) {
        .package-compare-section {
          padding-left: 2.5rem !important;
        }

        .description-section {
          padding-bottom: 5rem !important;
        }
      }
    }
    section.productrec-section,
    section.prices-section {
      overflow: hidden;
    }
    section.discounts-section {
      overflow-x: clip;
    }
  }
}

.product-page {
  section:not(.faq-section, .documents-section, .blog-section, .productrec-section, .prices-section, .package-compare-section, .additional-section, .discounts-section) {
    padding-bottom: 0 !important;
  }
}

section.google-score-mobile {
  display: none;
}

@media screen and (max-width: $breakpoint-md-max) {
  #utasbiztositas-page {
    .hero {
      padding-bottom: 0 !important;
    }
  }
  .product-page {
    section.hero {
      height: auto !important;
      width: 100% !important;
      background-color: #ffffff !important;
      padding: 0 !important;
      .container-xxl {
        padding-right: 0 !important;
        padding-left: 0 !important;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-md-max) {
  section.google-score-mobile {
    display: flex;
    .google-score-holder {
      position: relative;
      .container {
        display: flex;
        justify-content: center;
        margin-top: 0;
        .google-score {
          padding: 0;
          width: fit-content;
          height: 3rem;
          background: transparent;
          border: none;
          box-shadow: none;
          border-radius: 0;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          .logo-holder {
            padding: 0.5rem 1.5rem 0.5rem 0;
            border-bottom-width: 0;
            border-right: 0.125rem solid $N-500;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .wrapperzg {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 0.5rem 0 0.5rem 1.5rem;
            & > *:not(:last-child) {
              margin-right: 0.75rem;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-md-max) {
  .product-page {
    h2 {
      font-size: 1.75rem !important;
      line-height: 2.25rem !important;
    }
    h2.xsmall {
      font-size: 1.125rem !important;
      line-height: 1.75rem !important;
      font-weight: 600 !important;
    }

    section.discounts-section {
      padding-top: 1.625rem !important;
      padding-bottom: 0 !important;
    }
    section.description-section,
    section.prices-section,
    section.productrec-section,
    section.blog-section,
    section.documents-section,
    section.faq-section,
    section.package-compare-section {
      padding: 2.5rem 0 !important;
    }
    section.description-section,
    section.additional-section {
      padding-top: 2.5rem !important;
      padding-bottom: 0 !important;
      p {
        margin-bottom: 1.25rem !important;
      }
    }
    section.google-score-mobile {
      padding-top: 4rem !important;
      padding-bottom: 1.5rem !important;
    }
    section.package-compare-section {
      .container-xxl {
        padding-left: 2.5rem !important;
        padding-right: 2.5rem !important;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-sm-max) {
  .product-page {
    h2 {
      font-size: 1.5rem !important;
      line-height: 2rem !important;
    }
    section.description-section,
    section.productrec-section,
    section.blog-section,
    section.documents-section,
    section.faq-section {
      padding: 1.5rem 0 !important;
    }
    section.description-section,
    section.discounts-section {
      padding-top: 1.5rem !important;
      padding-bottom: 0 !important;
    }
    section.package-compare-section,
    section.prices-section {
      padding-top: 1.5rem !important;
      padding-bottom: 1rem !important;
    }
    section.additional-section,
    section.google-score-mobile {
      padding-top: 1.5rem !important;
      padding-bottom: 0 !important;
    }
    section.productrec-section {
      padding-top: 1.5rem !important;
      padding-bottom: 2.75rem !important;
    }
    section.package-compare-section {
      .subtitle-holder {
        margin-bottom: 2rem !important;
      }
      .container-xxl {
        padding-left: 1rem !important;
        padding-right: 1rem !important;

        .alert.alert-warning {
          display: flex;
        }
      }
    }
    section.prices-section {
      padding-bottom: 1.5rem !important;
    }
  }
}
@media screen and (max-width: $breakpoint-sm-max) {
  .product-page {
    .description-section {
      .description-holder {
        padding: 2rem !important;
      }
    }
    .alert.alert-warning {
      margin-bottom: 2rem;
      margin-top: 2rem;
    }
  }
}

@media screen and (max-width: $breakpoint-sm-max) {
  .product-page {
    .description-section {
      .description-holder {
        padding: 2rem !important;
      }
    }
    .alert.alert-warning {
      margin-bottom: 2rem;
      margin-top: 2rem;
    }
  }
}
@media screen and (max-width: $breakpoint-xs-max) {
  .product-page {
    .alert.alert-warning {
      margin-bottom: 1.5rem;
      margin-top: 1.5rem;
    }
    section.description-section,
    section.productrec-section,
    section.blog-section,
    section.documents-section,
    section.faq-section {
      padding: 1.5rem 0 !important;
    }
    section.description-section,
    section.discounts-section {
      padding-top: 1.5rem !important;
      padding-bottom: 0 !important;
    }
    section.package-compare-section,
    section.prices-section,
    section.productrec-section {
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important;
    }
    section.additional-section,
    section.google-score-mobile {
      padding-top: 1.5rem !important;
      padding-bottom: 0 !important;
    }

    .description-section {
      .description-holder {
        padding: 1.5rem 1rem !important;
        a.d-block.d-xs-none {
          margin-top: 1.5rem;
          display: block !important;
        }
      }
    }

    section.package-compare-section {
      .subtitle-holder {
        margin-bottom: 1.5rem !important;
      }
    }
  }
}

@media screen and (min-width: $breakpoint-xs) and (max-width: $breakpoint-xs-max) {
  .productrec-section {
    :deep(h2) {
      padding-bottom: 2rem;
    }
  }
}
#utasbiztositas-page .additional-section {
  padding-bottom: 0 !important;
}
#utasbiztositas-page.product-page {
  @media screen and (max-height: 700px) and (min-width: $breakpoint-md) {
    .prices-section {
      padding-top: 2.5rem !important;
      h2.medium.text-center,
      p.text-center.subtitle-holder {
        margin-bottom: 2rem;
      }
    }
    .package-compare-section {
      p.text-center.subtitle-holder {
        margin-bottom: 2rem;
      }
    }
    .additional-section {
      margin-top: 2.5rem !important;
      p.text-center {
        margin-bottom: 1.25rem;
      }
    }
    section.hero {
      padding-bottom: 6.375rem !important;
      height: unset !important;
      .container {
        margin-top: 2.5rem;
      }
      .minicalculator-holder {
        margin-top: 2.5rem !important;
        margin-bottom: 0 !important;
      }
    }
    .first-section {
      padding-top: 2.5rem;
    }
    .hero.no-discount {
      padding-bottom: 2.5rem !important;
    }
    .description-section {
      padding-top: 2.5rem !important;
    }
  }
}
#utasbiztositas-page.product-page.product-page-calculator {
  @media screen and (max-height: 700px) and (min-width: $breakpoint-md) {
    .description-section {
      padding-top: 2.5rem !important;
      padding-bottom: 2.5rem !important;
    }
  }
}

#balesetbiztositaas-page {
  .hero {
    height: 100% !important;
    padding-bottom: 8.75rem !important;
  }
}
.first-section {
  padding-top: 4rem;
}

.description-section {
  .text-bold {
    font-weight: 600 !important;
  }
  ul {
    margin-bottom: 0 !important;
  }
}
section.package-compare-section {
  .container-xxl {
    .row {
      margin-left: unset;
      margin-right: unset;
    }
  }
}

.prices-section {
  padding-top: 4rem;
}

@media screen and (max-width: $breakpoint-md-max) {
  #balesetbiztositaas-page {
    .hero {
      padding-bottom: 0 !important;
    }
  }
  .description-section {
    ul {
      margin-bottom: 1.5rem !important;
    }
  }
  .prices-section {
    .swiper-slide.card {
      margin-right: 1rem !important;
    }
  }
}

@media screen and (max-width: $breakpoint-xs-max) {
  #balesetbiztositas-page .minicalculator-holder {
    .input-holder {
      display: none;
    }
  }
  .description-section {
    ul {
      margin-bottom: 1.5rem !important;
    }
  }
  .minicalculator-holder {
    .tag-long.w-auto.bg-purple {
      margin-bottom: 1.5rem !important;
    }
  }
}
#balesetbiztositaas-page {
  @media screen and (max-height: 700px) and (min-width: $breakpoint-md) {
    .prices-section {
      h2.medium.text-center,
      p.text-center.subtitle-holder {
        margin-bottom: 2rem;
      }
      padding-top: 2.5rem !important;
    }
    .package-compare-section {
      h2.text-center {
        margin-bottom: 2rem;
      }
    }
    .additional-section {
      p.text-center {
        margin-bottom: 1.25rem;
      }
    }
    section.hero {
      padding-bottom: 6.375rem !important;
      height: unset !important;
      .container {
        margin-top: 2.5rem;
      }
      .minicalculator-holder {
        margin-top: 2.5rem !important;
        margin-bottom: 0 !important;
      }
    }
    .first-section {
      padding-top: 2.5rem;
    }
    .hero.no-discount {
      padding-bottom: 2.5rem !important;
    }
    .pb-0 {
      padding-top: 2.5rem !important;
    }
  }
}

#kgfb-page {
  .hero {
    height: 100% !important;
    padding-bottom: 7.75rem !important;
  }
}
.additional-section {
  padding-bottom: 4.25rem !important;
}
.discounts-section.without-checkbox {
  padding-top: 6.25rem !important;
}
.first-section {
  padding-top: 4rem;
}

.description-section {
  .text-bold {
    font-weight: 600 !important;
  }
  ul {
    margin-bottom: 1.5rem !important;
  }
}

.t-sm {
  color: #111a37;
}

.prices-section {
  padding-top: 4rem;
}

#kgfb-page {
  .discounts-section .card-discount .wrapperzg {
    margin-top: -2.5rem;
  }
  @media screen and (max-width: $breakpoint-xl-max) {
  }

  @media screen and (max-width: $breakpoint-md-max) {
    .hero {
      padding-bottom: 0 !important;
    }

    .discounts-section {
      padding-top: 1.375rem !important;
      margin-top: 0 !important;
    }
    .description-section {
      ul {
        margin-bottom: 1.5rem !important;
      }
    }
    .discounts-section .card-discount .wrapperzg {
      margin-top: 1rem;
    }
    .additional-section p {
      margin-bottom: 1.5rem;
    }
  }
  @media screen and (max-width: $breakpoint-sm-max) {
    .additional-section {
      .col-sm-6 {
        width: 100% !important;
      }
      .row {
        margin-left: -0.75rem;
        margin-right: -0.75rem;
      }

      .additional-card {
        padding: 1.5rem 1rem;
      }
    }

    .discounts-section {
      .container-xxl {
        padding-right: 1rem;
        padding-left: 1rem;
      }
    }

    .hero {
      .minicalculator-holder {
        .tag-long.w-auto.bg-purple {
          margin-bottom: 2rem;
        }
      }
    }

    .description-holder {
      padding: 1.5rem 1rem !important;
      p,
      li {
        font-size: 0.875rem !important;
        line-height: 1.25rem !important;
      }
    }
    .productrec-section {
      .medium.text-center {
        margin-bottom: 2rem;
      }
    }
  }
  @media screen and (max-width: $breakpoint-xs-max) {
    .description-section {
      ul {
        margin-bottom: 1.5rem !important;
      }
    }
    .additional-section {
      margin-bottom: 0;
      .row {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}

#kgfb-page .backtotop-holder-product {
  .col.col-12.col-lg-4 {
    width: 50% !important;
    &:last-child {
      display: none;
    }
  }
}
@media screen and (max-width: $breakpoint-sm-max) {
  .car-bottom-calc {
    .btn-secondary {
      padding: 0.5rem 1rem !important;
      font-weight: 500 !important;
      font-size: 0.875rem !important;
      line-height: 1.5rem !important;
      border-radius: 0.375rem !important;
    }
  }
  #kgfb-page {
    .additional-section {
      h2.medium.text-center {
        margin-bottom: 0.25rem !important;
      }
      p {
        margin-bottom: 0.75rem !important;
      }
    }
  }
}
#kgfb-page {
  @media screen and (max-height: 700px) and (min-width: $breakpoint-md) {
    section.hero {
      padding-bottom: 5.125rem !important;
      height: unset !important;
      .container {
        margin-top: 2.5rem;
      }
      .minicalculator-holder {
        margin-top: 2.5rem !important;
        margin-bottom: 0 !important;
      }
    }
    .hero.no-discount {
      padding-bottom: 2.5rem !important;
    }
    .description-section {
      padding-top: 2.5rem !important;
    }
    .description-section.pt-0 {
      padding-top: 1.75rem !important;
    }
    .additional-section {
      margin-top: 2.5rem !important;
      p.text-center {
        margin-bottom: 1.25rem !important;
      }
    }
  }
}

.faq-page-section {
  padding: 5rem 0 !important;
  .grid-holder {
    max-width: 71.5rem;
    margin: auto;
  }
  .grid-container {
    display: grid;
    grid-template-columns: $onethird $onethird $onethird;
    margin-left: -0.75rem;
    margin-right: -0.75rem;

    .grid-item {
      padding: 0.75rem;
    }
  }
  .section-content {
    padding-bottom: 4.25rem;
  }
  .title-holder {
    padding-bottom: 2.5rem;

    h1 {
      font-weight: 700;
      font-size: 2.75rem;
      line-height: 3.5rem;

      color: #1d2c5b;
    }
  }

  .subtitle-holder {
    padding-bottom: 1.75rem;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #1d2c5b;
  }
  .gyik-holder {
    background: #f8f8f8;
    border: 1px solid #f7f7fb;
    border-radius: 0.75rem;
    padding: 2rem;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .gyik-title-holder {
      .icon-holder {
        color: #ed4353;
        margin-bottom: 0.5rem;
        line-height: 1.5rem;
      }
      .text-holder {
        margin-bottom: 1rem;
      }
      h2,
      h4 {
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.5rem;
        color: #1d2c5b;
      }
    }

    .gyik-content-holder {
      .link-holder {
        padding-bottom: 0.5rem;
        line-height: 1.25rem;

        a {
          font-weight: 600;
          font-size: 0.875rem;
          color: #4e95fb;
        }
      }
      .link-holder:last-child {
        padding-bottom: 0;
      }
      .freqtopic-link-holder {
        line-height: 1rem;

        a {
          font-weight: 600;
          font-size: 0.875rem;
          color: #4e95fb;
        }
      }
    }
  }
  .freq-topic-section {
    .title-holder {
      padding-bottom: 1.75rem;
      h3 {
        font-size: 1.5rem;
        line-height: 2rem;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-md-max) {
  .faq-page-section {
    padding: 2.5rem 0 !important;
    .section-content {
      padding-bottom: 1.25rem;
    }
    .container-xxl {
      padding: 0 2.5rem;
    }

    .grid-container {
      grid-template-columns: percentage(1/2) percentage(1/2);
    }

    .title-holder {
      padding-bottom: 2rem;

      h1 {
        font-size: 2rem;
        line-height: 2.5rem;
      }
    }
    .subtitle-holder {
      padding-bottom: 1.25rem;
    }

    .gyik-holder {
      .gyik-content-holder {
        .link-holder {
          line-height: 1.5rem;

          a {
            font-weight: 600;
            font-size: 1rem;
          }
        }
      }
    }

    .freq-topic-section {
      .title-holder {
        padding-bottom: 1.25rem;

        h3 {
          font-size: 1.5rem;
          line-height: 2rem;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-xs-max) {
  .faq-page-section {
    padding: 2rem 0 !important;

    .container-xxl {
      padding: 0 1rem;
    }
    .grid-container {
      grid-template-columns: 100%;
      margin-left: -0.25rem;
      margin-right: -0.25rem;
      .grid-item {
        padding: 0.25rem;
      }
    }
    .section-content {
      padding-bottom: 1.25rem;
    }
    .title-holder {
      padding-bottom: 1.5rem;

      h1 {
        font-size: 1.5rem;
        line-height: 2rem;
      }
      h3 {
        font-size: 1.25rem;
        line-height: 1.75rem;
      }
    }

    .subtitle-holder {
      padding-bottom: 1.25rem;
      font-size: 0.75rem;
      line-height: 1.25rem;
    }

    .gyik-holder {
      text-align: center;
      padding: 1rem;
      .gyik-title-holder {
        .icon-holder {
          margin-bottom: 0.25rem;

          line-height: 1rem;
          svg {
            width: 1rem;
            height: 1rem;
          }
        }
        h2,
        h4 {
          font-size: 0.875rem;
          line-height: 1.25rem;
        }
      }
      .gyik-content-holder {
        .link-holder {
          line-height: 1.25rem;

          a {
            font-size: 0.875rem;
          }
        }
      }
    }

    .freq-topic-section {
      .title-holder {
        padding-bottom: 1.25rem;

        h3 {
          font-size: 1.25rem;
          line-height: 1.75rem;
        }
      }

      .gyik-holder {
        padding: 1rem;
        text-align: start;

        .gyik-title-holder {
          h4 {
            font-size: 0.875rem;
            line-height: 1.25rem;
          }
        }
        .gyik-content-holder {
          .freqtopic-link-holder {
            line-height: 1.25rem;

            a {
              font-size: 0.75rem;
            }
          }
        }
      }
    }
  }
}
</style>
