<template>
  <div>
    <q-uploader
      :multiple="maxFiles > 1 ? true : false"
      :auto-upload="false"
      :max-files="maxFiles"
      :max-file-size="maxFileSize"
      :max-file-name-length="maxFileNameLength"
      :accept="acceptFileType === null ? '' : acceptFileType"
      :disable="addedFilesLength <= maxFiles ? false : true"
      @failed="onUploadFail(info)"
      @uploaded="onUploadSuccess(info)"
      @rejected="onUploadReject"
      @added="onFilesAdded"
    >
      <template #header="">
        <div class="row no-wrap items-center">
          <div class="col text-center">
            <h2 class="q-uploader__title mb-16px">
              {{ title }}
            </h2>
            <p
              v-if="!imageAccepted"
              class="q-uploader__description mb-16px"
            >
              <template v-if="description">
                {{ description }}
              </template>
              <template v-else>
                {{ `Max. ${maxFiles} db fájlt tölthet fel.` }}
                {{ `A fájlok mérete egyenként max. ${Math.round(maxFileSize / 1024 / 1024)} MB lehet.` }}
                <br>
                {{ `Elfogadott fájl formátumok: ${acceptFileType.replace(/\./g, '')}.` }}
              </template>
            </p>
            <PhIconManager
              v-else
              ph-icon="ph-check-circle"          
              class="icon"
              :size="80"
              color="#10b981"
            />
          </div>
        </div>
      </template>
      <!-- eslint-disable-next-line vue/no-template-shadow -->
      <template
        #list="scope"
      >
        <div
          v-if="!imageAccepted"
          class="file-list mb-32px"
        >
          <p>{{ setScope(scope) }}</p>
          <div
            v-for="(file, index) in scope.files"
            :key="file.__key"
            class="file-list-item"
            :class="{ 'file-list-item--disabled': scope.isUploading }"
          >
            <div
              v-if="
                checkFileSize(file) === false || checkFileNameLength(file) === false
                  ? scope.removeFile(scope.files[index])
                  : true
              "
            >
              <PhIconManager
                v-if="ACCEPT_FILE_TYPES.IMAGE.includes(getFileFormat(file))"
                ph-icon="ph-image-square"
                :size="16"
              />
              <PhIconManager
                v-else
                ph-icon="ph-file"
                :size="16"
              />
              <span class="file-list-item-title ps-4px">
                {{ desiredFileName !== null ? desiredFileName : file.name }}
              </span>

              <q-spinner
                v-if="scope.isUploading"
                class="q-uploader__spinner"
              />
            </div>

            <q-btn @click="confirmDelete(index)">
              <PhIconManager
                ph-icon="ph-trash"
                :size="16"
              />
            </q-btn>

            <q-dialog
              v-model="showDeleteConfirmator"
              class="confirm-dialog"
              persistent
            >
              <div class="confirm-dialog__wrapper">
                <div class="confirm-dialog__header mb-24px text-center">
                  <h4 class="confirm-dialog__title mb-24px">
                    Biztos, hogy törölni szeretnéd?
                  </h4>
                  <p class="confirm-dialog__content mb-0">
                    A döntés visszavonhatatlan.
                  </p>
                </div>

                <div class="confirm-dialog__actions">
                  <q-btn
                    v-close-popup
                    class="btn btn-dark-blue me-8px"
                    flat
                    label="Mégsem"
                    color="primary"
                    @click="showDeleteConfirmator = false"
                  />
                  <q-btn
                    v-close-popup
                    class="btn btn-outline btn-red ms-8px"
                    flat
                    label="Törlés"
                    color="primary"
                    @click="removeFileAtIndex(indexForFileToRemove)"
                  />
                </div>
              </div>
            </q-dialog>
          </div>
        </div>
        <Alert
          v-if="uploadStatus && !imageAccepted"
          class="mb-48px mt-16px"
          :color="uploadStatus"
          :title="
            uploadStatus === 'success'
              ? 'Sikeres feltöltés'
              : 'Sikertelen feltöltés'
          "
          :content="uploadStatus === 'error' && validationError"
        />
        <div
          v-if="addedFilesLength < maxFiles && !imageAccepted"
          class="text-center"
        >
          <q-btn
            class="q-btn-upload"
            type="a"
            @click="scope.pickFiles;hasThereBeenAnError = false"
          >
            <img
              src="@/assets/upload-icon.svg"
              alt="alt"
            >
            <q-uploader-add-trigger />
          </q-btn>
        </div>
        <p
          v-if="addedFilesLength < maxFiles && !imageAccepted"
          class="small mt-16px mb-0 text-center"
        >
          {{ dragAndDropText }}
        </p>
        <div
          v-if="footerText !== null"
          class="row no-wrap items-center"
        >
          <div class="col text-center">
            <p
              class="q-uploader__description mb-16px"
              style="color: #f59e0b; font-style: italic;"
            >
              {{ footerText }}
            </p>
          </div>
        </div>
        <div
          v-if="footerText !== null"
          class="row no-wrap items-center"
        >
          <div class="col text-center">
            <p
              class="q-uploader__description mb-16px"
            >
              Előző feltöltött kép:
            </p>
          </div>
        </div>
        <div
          v-if="footerText !== null"
          class="row no-wrap items-center"
        >
          <div class="col text-center">
            <img :src="base64ImageString">
          </div>
        </div>
      </template>
    </q-uploader>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import {
  getFileFormat,
  ACCEPT_FILE_TYPES,
} from '../../utils/inputs/fileInputHelper'

const props = defineProps({
  title: {
    type: String,
    required: true,
    default: 'Fájl feltöltés',
  },
  description: {
    type: String,
  },
  dragAndDropText: {
    type: String,
    default: 'Töltsd fel vagy húzd be a fájlokat!',
  },
  minFileSize: {
    type: Number,
    default: 1,
  },
  maxFileSize: {
    type: Number,
    default: 3000000,
  },
  maxFiles: {
    type: Number,
    default: 3,
  },
  maxFileNameLength: {
    type: Number,
    default: 60,
  },
  acceptFileType: {
    type: String,
    default: null,
  },
  renameFile: {
    type: String,
    default: '',
  },
  initFiles: {
    type: Object,
    default: null,
  },
  maxFileSizeError: {
    type: String,
    required: false,
  },
  minFileSizeError: {
    type: String,
  },
  footerText: {
    type: String,
    default: null,
  },
  base64ImageString: {
    type: String,
    default: null,
  },
  imageAccepted: {
    type: Boolean,
    default: false,
  }
})

const scope = ref(null)

const emits = defineEmits(['filesAdded', 'filesRemoved'])

const uploadStatus = ref(null)
const validationError = ref('')
const showDeleteConfirmator = ref(false)
const addedFilesLength = ref(0)
const indexForFileToRemove = ref(0)
const hasThereBeenAnError = ref(false)
const lastErrorTimestamp = ref(0)

const ERROR_MESSAGES = {
  MAX_FILES: `Legfeljebb ${props.maxFiles} fájlt tölthető fel.`,
  MAX_FILE_SIZE: props.maxFileSizeError || `A kiválasztott fájlok mérete egyenként nem haladhatja meg a ${props.maxFileSize / 1024 / 1024} MB-ot.`,
  MIN_FILE_SIZE: props.minFileSizeError || `A kiválasztott fájlok mérete egyenként nem lehet kevesebb ${props.minFileSize / 1024 / 1024} MB-nél.`,
  ACCEPT: 'Nem megengedett fájl formátum!',
  DUPLICATE: 'A fájl már hozzá lett adva.',
  MAX_NAME_LENGTH: `A fájlnév maximum ${props.maxFileNameLength} karakter hosszúságú lehet.`
}

const desiredFileName = ref(null)

const checkFileSize = (file) =>
  file.size >= props.minFileSize;

const checkFileNameLength = (file) =>
  file.name.length <= props.maxFileNameLength;

function onFilesAdded(files) {
  if (props.minFileSize > 1 && files[0].size < props.minFileSize) {
    hasThereBeenAnError.value = true
    uploadStatus.value = 'error'
    validationError.value =
      ERROR_MESSAGES[
        getErrorMessageType([
          {
            failedPropValidation: 'min-file-size',
          },
        ])
      ]
  }
  else if (files.some(f => !checkFileNameLength(f))) {
    hasThereBeenAnError.value = true
    uploadStatus.value = 'error'
    validationError.value =
      ERROR_MESSAGES[
        getErrorMessageType([
          {
            failedPropValidation: 'max-name-length',
          },
        ])
      ]
  }
  else if (addedFilesLength.value < props.maxFiles) {
    if (!hasThereBeenAnError.value) {
      uploadStatus.value = null
    }

    /*
     Mivel a q-uploader egy külsős rendszer így nincs hozzáférésünk minden eseményhez, eshetőséghez.
     Emiatt nincs lehetőség figyelni, mikor törént az utolsó hiba, egy feltöltési csomagban keletkezett több, valid fájllal vagy sem.
     Ezt figyeli a lastErrorTimestamp változónk. Abban az esetben, ha a jelenlegi idő és az utolsó hiba között legalább 1500 millisecundum eltelt,
     feltételezzük, hogy a hiba egy korábbi feltöltésből ered és nem az aktuális feltöltésből. Emiatt a hiba megszüntethető.
     Figyelni éles rendszeren, hogy elegendő-e a 1500 millisecundum.
    */

    if (Date.now() - lastErrorTimestamp.value > 1500) {
      hasThereBeenAnError.value = false
      uploadStatus.value = null
    }

    addedFilesLength.value += files.length

    if (props.renameFile !== '' && files.length === 1) {
      const file = files[0]
      desiredFileName.value = props.renameFile + '.' + getFileFormat(file)
      emits('filesAdded', file)
    } else {
      emits('filesAdded', files)
    }
  }
}

function onUploadFail(info) {
  uploadStatus.value = 'error'
}

function onUploadSuccess(info) {
  uploadStatus.value = 'success'
}

function getErrorMessageType(error) {
  return error[0].failedPropValidation.replace(/-/g, '_').toUpperCase()
}

function onUploadReject(error) {
  hasThereBeenAnError.value = true
  uploadStatus.value = 'error'
  validationError.value = ERROR_MESSAGES[getErrorMessageType(error)]
  lastErrorTimestamp.value = Date.now()
}

function confirmDelete(index) {
  indexForFileToRemove.value = index
  showDeleteConfirmator.value = true
}

function removeFileAtIndex(index) {
  const file = scope.value.files[index];
  scope.value.removeFile(file);
  showDeleteConfirmator.value = false;
  removeFileFromParent(file);
}

function removeFileFromParent(file) {
  if (props.maxFileSize === 1) {
    emits('filesRemoved')
  } else {
    emits('filesRemoved', file)
  }
  addedFilesLength.value--
}

function setScope(sc) {
  scope.value = sc;
  if (props.initFiles !== null) {
    sc.files = props.initFiles
    addedFilesLength.value = sc.files.length
  }
  return
}
</script>

<script>
export default {
  name: 'FileUploader',
}
</script>

<style lang="scss" scoped>
.q-uploader {
  width: 100%;
  border-radius: 0.75rem;
  box-shadow: none;
  max-height: 100%;

  &__title {
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 600;
    color: $D-500;

    @media (min-width: $breakpoint-xs) {
      font-size: 1.25rem;
      font-weight: bold;
      line-height: 1.75rem;
    }
  }

  &__description {
    font-size: 0.75rem;
    line-height: 1.25rem;

    @media (min-width: $breakpoint-xs) {
      font-size: 0.875rem;
    }
  }

  &__spinner {
    font-size: 1rem;
    margin-left: 0.75rem;
  }
}

.q-btn-upload {
  padding: 0;
  border-radius: 0.75rem;

  &:active {
    box-shadow: none;
  }
}

.small {
  font-size: 0.75rem;
  color: $N-800;
}

:deep(.q-uploader__dnd) {
  background: rgba(78, 149, 251, 0.25);
  border: 0.125rem dashed $B-500;
  border-radius: 0.75rem;
  margin: 0.5rem;
  outline: none;
}

:deep(.q-uploader__list),
:deep(.q-uploader__header) {
  .row,
  .row > * {
    width: auto;
  }
}

:deep(.q-uploader__header) {
  background-color: $N-100;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  padding: 1rem 1rem 0;

  @media (min-width: $breakpoint-xs) {
    padding: 2rem 2rem 0;
  }

  @media (min-width: $breakpoint-xl) {
    padding: 2.5rem 2.5rem 0;
  }
}

:deep(.q-uploader__list) {
  min-height: auto;
  background-color: $N-100;
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
  padding: 0 1rem 1rem;
  overflow: hidden;

  @media (min-width: $breakpoint-xs) {
    padding: 0 2rem 2rem;
  }

  @media (min-width: $breakpoint-xl) {
    padding: 0 2.5rem 2.5rem;
  }
}

.file-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;

  &:not(:last-child),
  &:first-child {
    border-bottom: 1px solid #d5d9eb;
  }

  &--disabled {
    opacity: 0.7;
  }

  &-title {
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: rgb(29, 44, 91);
  }

  .q-btn {
    padding: 0;
  }
}

.confirm-dialog {
  &__wrapper {
    padding: 1.5rem;
    background: $N-50;
    box-shadow: 0rem 0.625rem 0.9375rem rgba(0, 0, 0, 0.1),
      0rem 0.25rem 0.375rem rgba(0, 0, 0, 0.05);
    border-radius: 1rem;
  }

  &__content {
    font-size: 0.75rem;
    line-height: 1.25rem;

    @media (min-width: $breakpoint-xs) {
      font-size: 0.875rem;
    }
  }

  &__title {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.75rem;
    text-align: center;
    color: $red;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;

    :deep(.btn) {
      .q-btn__content {
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 1.5rem;
        text-transform: capitalize;
        color: $white;
      }
    }

    :deep(.btn-red) {
      .q-btn__content {
        color: $red;
      }
    }

    .btn {
      padding: 0.5rem 1rem;
      border-radius: 0.5rem;
      width: 100%;

      &-outline {
        background-color: transparent;
        border: 1px solid $D-500;
        color: $D-500;
      }

      &-dark-blue {
        border-color: $D-500;
        background-color: $D-500;
        color: $white;
      }

      &-red {
        background-color: transparent;
        border-color: $red;
        color: $red;
      }
    }
  }
}
</style>
